import React from "react";
import LetsTalk from "../components/LetsTalk";
import { Helmet } from "react-helmet";

const people = [
  {
    name: "Tomer Simonov",
    role: "Co-Founder, CEO",
    image: "/assets/images/tomer.jpg",
    link: "https://www.linkedin.com/in/tomer-simonov/",
    description:
      "With over a decade of experience across diverse markets—ranging from small-scale projects to large ventures—Tomer understands the complexities of property management and investment protection. His hands-on experience inspired him to create innovative solutions that help property managers mitigate risk, ensure compliance, and scale their portfolios with confidence.",
  },
  {
    name: "Dujo Duvnjak",
    role: "Co-Founder, CTO",
    image: "/assets/images/dujo.jpg",
    link: "https://www.linkedin.com/in/dujoduvnjak/",
    description:
      "With over 20 years as a development expert and CTO, Dujo combines technical expertise with creative leadership to build high-performing teams that turn ideas into impactful products.",
  },
  {
    name: "Taylor Malkus",
    role: "Co-Founder, VP Sales",
    image: "/assets/images/taylor.jpg",
    link: "https://www.linkedin.com/in/taylor-malkus/ ",
    description:
      "Taylor’s career in PropTech began at Buildium, where he advised hundreds of clients—everyone from accidental landlords to large institutional investors—on how to modernize and streamline their operations through technology. As Co-Founder of Lighthouse, Taylor now applies his expertise and distinctive strategic perspective to helping the SFR industry tap into enterprise-level technology historically inaccessible to them, empowering businesses to better manage risk, detect fraud, and unlock new avenues of growth.",
  },
  {
    name: "Dave DoCouto",
    role: "Regional Sales Lead",
    image: "/assets/images/dave.jpg",
    link: "https://www.linkedin.com/in/dave-docouto-01b93913/",
    description:
      "Dave has advised property managers of every size and asset class on the benefits of technology for over a decade. His journey in PropTech began as one of the first Sales hires at Buildium, eventually gaining firsthand experience in every customer-facing role, giving him a deep understanding and appreciation of the unique challenges of property management—insights he now leverages to help PMs stay ahead of a rapidly evolving market through innovative technology and personalized solutions.",
  },
];

const AboutUs: React.FC = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <title>Lighthouse - About Us</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="content-page">
        <article className="about">
          <div>
            <h2 className="intro">
              At Lighthouse, we understand the complexities of the rental market
              because we've lived them.
            </h2>
          </div>
          <div className="mission">
            <h2>Our Mission</h2>
            <div>
              <h3>
                <strong>
                  At Lighthouse, we empower property managers to thrive in an
                  ever-evolving industry.
                </strong>
              </h3>
              <p>
                Built on over a decade of hands-on rental management experience,
                we understand the challenges Property Managers face. From
                navigating rapidly changing legislation to protecting client
                investments and maximizing NOI, we’re here to help you stay
                compliant, reduce liability, and strengthen relationships with
                property owners. Our modern risk mitigation solutions deliver
                enhanced coverage and financial peace of mind, streamlining your
                leasing operations and saving you time. With Lighthouse, you’re
                equipped to stay ahead, grow confidently, and focus on what
                matters most.
              </p>
            </div>
          </div>

          <LetsTalk label="Learn More" size="large" />

          <div className="team">
            <h2>Our Team</h2>
            {people.map((person) => (
              <div className="person">
                <div
                  className="image"
                  style={{ backgroundImage: `url(${person.image})` }}
                ></div>
                <div className="content">
                  <h3>
                    {person.name}{" "}
                    <a
                      href={person.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img alt="LinkedIn" src="/assets/icons/linkedin.png" />
                    </a>
                  </h3>
                  <h4>{person.role}</h4>
                  <p>{person.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="more">
            <h2>Want to know more?</h2>
            <LetsTalk label="Let's Talk!" size="large" />
          </div>
        </article>
      </div>
    </div>
  );
};

export default AboutUs;
