import React, { useEffect, useState } from "react";
import { USAMap, USAStateAbbreviation } from "@mirawision/usa-map-react";
import states from "../data/states.json";

type StateInfo = {
  state: string;
  rules: string[];
} | null;

const Map: React.FC = () => {
  const defaultState: USAStateAbbreviation = "CA";
  const [stateInfo, setStateInfo] = useState<StateInfo>(
    states[defaultState as keyof typeof states]
  );
  const [selectedState, setSelectedState] =
    useState<USAStateAbbreviation | null>(defaultState);
  const [autoChange, setAutoChange] = useState<boolean>(true);

  useEffect(() => {
    if (!autoChange) return;

    const interval = setInterval(() => {
      const stateKeys = Object.keys(states) as USAStateAbbreviation[];
      const randomState =
        stateKeys[Math.floor(Math.random() * stateKeys.length)];
      setSelectedState(randomState);
      setStateInfo(states[randomState as keyof typeof states]);
    }, 5000);

    return () => clearInterval(interval);
  }, [autoChange]);

  useEffect(() => {
    if (selectedState) {
      document.querySelector(".usa-state.active")?.classList.remove("active");
      document
        .querySelector(`.usa-state.${selectedState.toLowerCase()}`)
        ?.classList.add("active");
    }
  }, [selectedState]);

  useEffect(() => {
    const handleMouseOver = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("usa-state")) {
        target.classList.add("highlighted");
      }
    };

    const handleMouseOut = (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains("usa-state")) {
        target.classList.remove("highlighted");
      }
    };

    document.querySelectorAll(".usa-state").forEach((element) => {
      element.addEventListener("mouseover", handleMouseOver as EventListener);
      element.addEventListener("mouseout", handleMouseOut as EventListener);
    });

    return () => {
      document.querySelectorAll(".usa-state").forEach((element) => {
        element.removeEventListener(
          "mouseover",
          handleMouseOver as EventListener
        );
        element.removeEventListener(
          "mouseout",
          handleMouseOut as EventListener
        );
      });
    };
  }, []);

  const handleStateClick = (state: USAStateAbbreviation) => {
    setAutoChange(false);
    setSelectedState(state);
    setStateInfo(states[state as keyof typeof states] || null);
  };

  return (
    <section className="state-info">
      <h2>Explore what's happening in your state.</h2>
      <h3>See legislation details by clicking on any state on the map.</h3>
      <div className="map">
        <div className="image">
          <USAMap defaultState={{ fill: "#555", onClick: handleStateClick }} />
        </div>
        <div className="content">
          {stateInfo ? (
            <>
              <h3>{stateInfo.state}</h3>
              <ul>
                {stateInfo.rules.map((rule, index) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            </>
          ) : (
            <p>Click on a state to see its details.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Map;
