import React from "react";
import { NavLink } from "react-router-dom";

const Deposits: React.FC = () => {
  return (
    <section className="feature">
      <div className="image">
        <img src="/assets/images/deposits.png" alt="Deposits" />
      </div>
      <div className="content">
        <h2>Deposits That Deliver</h2>
        <p>
          Our modern Security Deposit Alternative helps you{" "}
          <strong>
            <em>fill vacancies faster</em>
          </strong>{" "}
          and more securely, streamlining operations and effortlessly delivering
          your clients <strong>maximum protection</strong> at{" "}
          <strong>zero additional cost</strong>.
        </p>
        <p>
          Lighthouse partners instantly get up to 2 months' Rent in{" "}
          <strong>
            <em>guaranteed protection</em>
          </strong>{" "}
          from tenant-related losses, including unpaid rent & Damages.
        </p>
        <p>Make bad-debt a thing of the past, today!</p>

        <div className="learn">
          <NavLink className="button" to="how-it-works">
            See How it Works
          </NavLink>
        </div>
      </div>
    </section>
  );
};

export default Deposits;
