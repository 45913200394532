import React from "react";
import faqsData from "../data/faqs.json";
import { Helmet } from "react-helmet";

const FAQs: React.FC = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <title>Lighthouse - FAQs</title>
      </Helmet>
      <div className="content-page">
        <article className="faq">
          <h1>Frequently Asked Questions</h1>
          {faqsData.map((section, index) => (
            <div key={index}>
              <h2>{section.title}</h2>
              <ul>
                {section.questions.map((faq, idx) => (
                  <li key={idx}>
                    <h3>{faq.question}</h3>
                    <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </article>
      </div>
    </div>
  );
};

export default FAQs;
