import React from "react";

interface LetsTalkProps {
  label: string;
  size?: "small" | "medium" | "large";
}

const LetsTalk: React.FC<LetsTalkProps> = ({ label, size }) => {
  const buttonClass = size ? `button button--${size}` : "button";

  return (
    <div className="learn">
      <a
        className={buttonClass}
        href="https://calendly.com/taylor_malkus/hello"
        target="_blank"
        rel="noreferrer"
      >
        {label}
      </a>
    </div>
  );
};

export default LetsTalk;
