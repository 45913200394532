import React from "react";
import testimonialsData from "../data/testimonials.json";

const TestimonialList: React.FC = () => {
  return (
    <section className="testimonials">
      {testimonialsData.map((testimonial, index) => (
        <div className="testimonial" key={index}>
          <p>"{testimonial.text}"</p>
          <p>
            <strong>{testimonial.author}</strong>
            <br />
            {testimonial.company}
          </p>
        </div>
      ))}
    </section>
  );
};

export default TestimonialList;
