import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer>
      <p>
        <Link to="/">
          <img alt="Lighthouse" src="/assets/images/lighthouse-white.png" />
        </Link>
      </p>
      <p>
        <span className="company">Lighthouse Inc. • </span>
        <a href="mailto:contact@mylighthouse.co">
          contact@mylighthouse.co
        </a>{" "}
        •&nbsp;
        <a href="tel:+18052720003">(805) 272-0003</a>
      </p>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About Us</Link>
        <Link to="/faqs">FAQs</Link>
      </nav>
    </footer>
  );
};

export default Footer;
