import React from "react";
import LetsTalk from "../components/LetsTalk";
import { Helmet } from "react-helmet";
import TestimonialSlider from "../components/TestimonialSlider";

const How: React.FC = () => {
  return (
    <div className="wrapper-">
      <Helmet>
        <title>Lighthouse - How it Works</title>
      </Helmet>
      <div className="content-page-">
        <div className="title-section">
          <h1>How it Works</h1>
          <p></p>
        </div>
        <div className="wrapper">
          <article className="faq" style={{ padding: "40px 0" }}>
            <h2>Fill Vacancies Faster with Simple, Affordable Coverage</h2>
            <p>
              Property managers offering Lighthouse benefit from more
              flexibility and protection against tenant-related defaults & bad
              debt than traditional deposits offer, and report reduced vacancy
              rates by making move-in costs more competitive for renters.
            </p>
            <p>
              Once invited, tenants are approved in minutes and can sign up
              immediately by paying a small, non-refundable fee instead of a
              much larger upfront deposit. Tenants typically save several
              hundred dollars or more, enabling them to move-in sooner and
              maintain more of a nest-egg, leading to higher satisfaction and
              better renewal rates.
            </p>
          </article>
        </div>
        <div className="gradient">
          <div className="howitworks">
            <h2>How it Works?</h2>
            <div className="steps">
              <div className="step">
                <div className="number">1</div>
                <h3>Fill Vacancies Faster</h3>
                <ul>
                  <li>Offer Lighthouse for flexible, lower-cost move-ins.</li>
                  <li>
                    Tenants get approved in minutes with a small, non-refundable
                    fee.
                  </li>
                </ul>
              </div>
              <div className="step">
                <div className="number">2</div>
                <h3>Hassle-Free Lease Renewals</h3>
                <ul>
                  <li>
                    When tenants renew, Lighthouse renews automatically—no extra
                    steps.
                  </li>
                </ul>
              </div>
              <div className="step">
                <div className="number">3</div>
                <h3>Stress-Free Move-Outs</h3>
                <ul>
                  <li>$0 Balance? Lease ends, no paperwork.</li>
                  <li>
                    Tenant Owes? Submit a claim, get paid fast—we handle tenant
                    repayment.
                  </li>
                </ul>
              </div>
              <div className="step">
                <div className="number">4</div>
                <h3>Get Started Today!</h3>
                <ul>
                  <li>
                    Protect your properties with simpler, smarter coverage.
                  </li>
                </ul>
              </div>
            </div>
            <LetsTalk label="Get Started" size="large" />
          </div>
        </div>
        <div className="wrapper">
          <section className="feature" style={{ padding: "60px 0" }}>
            <div className="content">
              <h2>Lease renewal coming up? We've got you covered</h2>
              <p>
                When tenants renew, Lighthouse does too! No extra steps or
                approvals—just continuous coverage. Need to make adjustments?
                <br />
                We make it simple.
              </p>
            </div>
            <div className="image">
              <img src="/assets/images/renewals.png" alt="Renewals" />
            </div>
          </section>
          <section className="feature" style={{ padding: "60px 0" }}>
            <div className="image">
              <img src="/assets/images/moveouts.png" alt="Move-Outs" />
            </div>
            <div className="content">
              <h2>Move-Outs Made Simple</h2>
              <p>
                When it's time to move out, there are two possible
                outcomes—either way, no chasing tenants, no deposit
                disputes—just{" "}
                <strong>
                  <em>guaranteed</em>
                </strong>{" "}
                protection. <strong>$0 Balance</strong>? You're done! The lease
                ends, and there's nothing more to do. No deposit to return, no
                extra paperwork—just a smooth move-out & and another happy
                owner!
              </p>
            </div>
          </section>
        </div>
        <TestimonialSlider />
        <div className="wrapper">
          <div style={{ textAlign: "center", padding: "80px 20px" }}>
            <h2>BONUS: More Coverage = Less Bad Debt</h2>
            <div>
              <p>
                Lighthouse partners get up to 2x Rent in coverage on every lease
                and report less bad debt after Year 1 thanks to the
                affordability of our service for tenants. If a tenant misses a
                month's rent and leaves behind damages, you're not always stuck
                writing off the overage like you normally would have to with a
                cash deposit. That's a win you can take all the way to the bank!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default How;
