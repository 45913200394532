import React from "react";
import Slider from "react-infinite-logo-slider";
import TestimonialList from "./TestimonialList";

const Partners: React.FC = () => {
  return (
    <section className="partners">
      <h2>Our Partners</h2>
      <Slider
        duration={40}
        pauseOnHover={true}
        blurBorders={false}
        blurBorderColor={"#fff"}
      >
        <Slider.Slide>
          <img src="/assets/partners/allcounty.png" alt="All County" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/arrowstar.png" alt="ArrowStar" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/assetliving.png" alt="Asset Living" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/evernest.png" alt="Evernest" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/greystar.png" alt="Greystar" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/keyrenter.png" alt="Key Renter" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/meridian.png" alt="Meridian" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/pmi.png" alt="PMI" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/swartz.png" alt="Swartz Properties" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/abbeyhill.png" alt="Abbey Hill" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/narpm.png" alt="NARPM" />
        </Slider.Slide>
        <Slider.Slide>
          <img src="/assets/partners/realty.png" alt="Realty Boulevard" />
        </Slider.Slide>
      </Slider>
      <TestimonialList />
    </section>
  );
};

export default Partners;
