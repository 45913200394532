import React from "react";

const Support: React.FC = () => {
  return (
    <section className="feature">
      <div className="content">
        <h2>Live Support, Every Time</h2>
        <p>
          We understand how frustrating it can be when you're searching for help
          and are met with unresponsive service or endless chatbot loops. At
          Lighthouse, we do things differently.
        </p>
        <p>
          No chatbots, no runaround - just real people, ready to assist you.
          Your success is our priority, and we're here to provide answers and
          solutions when you need them most.
        </p>
        <p>
          Call us at <strong>(805) 272-0003</strong> and experience the
          difference. We stand behind our service, and we're here to make your
          life easier.
        </p>
        <div className="learn">
          <a className="button" href="tel:+18052720003">
            Call Us
          </a>
        </div>
      </div>
      <div className="image">
        <img src="/assets/images/support.png" alt="Support" />
      </div>
    </section>
  );
};

export default Support;
