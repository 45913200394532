import { useState } from "react";
import { NavLink } from "react-router-dom";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <>
      <nav className="mobile">
        {isOpen && (
          <ul className="menu">
            <li>
              <NavLink to="/" onClick={closeMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" onClick={closeMenu}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/how-it-works" onClick={closeMenu}>
                How it Works
              </NavLink>
            </li>
            <li>
              <NavLink to="/about" onClick={closeMenu}>
                Login
              </NavLink>
            </li>
          </ul>
        )}
      </nav>
      <div className="hamburger" onClick={toggleMenu}>
        ☰
      </div>
    </>
  );
};

export default MobileMenu;
