import React from "react";
import Map from "../components/Map";
import Support from "../components/Support";
import Deposits from "../components/Deposits";
import { TypeAnimation } from "react-type-animation";
import faqsData from "../data/faqs.json";
import Partners from "../components/Parners";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import LetsTalk from "../components/LetsTalk";

const Home: React.FC = () => {
  return (
    <>
      <div className="wrapper">
        <section className="hero">
          <h1>
            Property Protection, <em>Perfected</em>.
          </h1>
          <TypeAnimation
            sequence={[
              "We ensure compliance",
              2000,
              "We reduce liability",
              2000,
              "We strengthen your position with owners",
              2000,
              "We have your back.",
              2000,
            ]}
            wrapper="h2"
            speed={50}
            cursor={false}
          />
          <p>
            Fraud Prevention + Risk Mitigation Partner for Property Managers
          </p>
          <LetsTalk label="Learn More" size="large" />
        </section>
        <Map />
        <div className="features">
          <Deposits />
          <Support />
        </div>
      </div>
      <Partners />
      <div className="wrapper">
        <div className="faq-preview">
          <h2>Frequently Asked Questions</h2>
          {faqsData.map((section, index) => (
            <article>
              {index === 0 && (
                <Accordion allowZeroExpanded={true} key={index}>
                  {section.questions.map((faq, idx) => (
                    <AccordionItem key={idx}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h3>{faq.question}</h3>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              )}
            </article>
          ))}
        </div>
      </div>
    </>
  );
};

export default Home;
