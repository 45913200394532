import React, { useState, useEffect } from "react";
import testimonialsData from "../data/testimonials.json";

const TestimonialSlider: React.FC = () => {
  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000);

    return () => clearInterval(interval);
  }, [index, isPaused]);

  const nextTestimonial = () => {
    setIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
  };

  const prevTestimonial = () => {
    setIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const handleUserInteraction = () => {
    setIsPaused(true);
  };

  return (
    <div className="testimonial-slider">
      <h2>What Our Customers Are Saying</h2>
      <section className="wrapper">
        <div className="testimonial">
          <p>"{testimonialsData[index].text}"</p>
          <p>
            <strong>{testimonialsData[index].author}</strong>
            <br />
            {testimonialsData[index].company}
          </p>
        </div>

        <div className="navigation">
          <span
            className="left"
            onClick={prevTestimonial}
            onMouseEnter={handleUserInteraction}
          ></span>
          <span
            className="right"
            onClick={nextTestimonial}
            onMouseEnter={handleUserInteraction}
          ></span>
        </div>
      </section>
    </div>
  );
};

export default TestimonialSlider;
