import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";

const Header: React.FC = () => {
  return (
    <div className="wrapper">
      <header>
        <div className="logo">
          <Link to="/">
            <img alt="Lighthouse" src="/assets/images/lighthouse.png" />
          </Link>
        </div>
        <nav className="desktop">
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/how-it-works">How it Works</Link>
          <a
            href="https://landlord.mylighthouse.co"
            target="_blank"
            rel="noreferrer"
            className="button"
          >
            Login
          </a>
        </nav>
        <MobileMenu />
      </header>
    </div>
  );
};

export default Header;
