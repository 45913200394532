import React from "react";
import { Routes, Route } from "react-router-dom";
import Hotjar from "@hotjar/browser";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import FAQs from "./pages/FAQs";
import How from "./pages/How";
import "./scss/App.scss";
import "./scss/Mobile.scss";

const siteId = 5313790;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const App: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <div className="page">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/how-it-works" element={<How />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
